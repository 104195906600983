import * as React from 'react';
import * as Yup from 'yup';
import { TextInput, Form, Select, Check } from '@mentimeter/ragnar-form';
import type { ButtonT } from '@mentimeter/ragnar-ui';
import { Trans, useTranslation } from 'react-i18next';
import { Meta } from '../../ui/typography';
import { Button, Link } from '../../ui/actions';
import { formatDate } from '../../utils/formatDate';
import type { FormValuesT } from './types';

interface PropsT {
  formFields: FormValuesT;
  dates?: string[];
  status: ButtonT['state'] | undefined;
  handleSubscribe: (data: FormValuesT) => void;
  buttonTitle: string;
  consent?: boolean;
}

const selectDefaultValue = {
  key: 'defaultValue',
  value: 'defaultValue',
  label: '- select -',
  disabled: true,
};

export const NotifyForm = ({
  formFields,
  status,
  buttonTitle,
  dates,
  handleSubscribe,
  consent,
}: PropsT) => {
  const { t } = useTranslation('common');

  const { email, name, jobTitle, company, webinar } = formFields;
  const hasEmailField = 'email' in formFields;
  const hasNameField = 'name' in formFields;
  const hasJobTitleField = 'jobTitle' in formFields;
  const hasCompanyField = 'company' in formFields;
  const hasDateField = dates && 'date' in formFields;

  const generateValidationSchema = () => ({
    ...(hasEmailField && {
      email: Yup.string()
        .email(t('notify_form.validation.email_invalid'))
        .required(t('notify_form.validation.email_required'))
        .matches(
          /^(?!.*gmail|.*hotmail|.*yahoo|.*outlook).*$/,
          t('notify_form.validation.work_email_required'),
        )
        .max(150, t('notify_form.validation.email_long')),
    }),
    ...(hasNameField && {
      name: Yup.string()
        .required(t('notify_form.validation.name_required'))
        .max(150, t('notify_form.validation.name_long')),
    }),
    ...(hasJobTitleField && {
      jobTitle: Yup.string()
        .required(t('notify_form.validation.job_title_required'))
        .max(150, t('notify_form.validation.job_title_long')),
    }),
    ...(hasDateField && {
      date: Yup.string()
        .required(t('notify_form.date_required'))
        .notOneOf([selectDefaultValue.value], t('notify_form.date_required')),
    }),
    ...(hasCompanyField && {
      company: Yup.string()
        .required(t('notify_form.validation.company_required'))
        .max(150, t('notify_form.validation.company_long')),
    }),
  });

  const validationSchema = Yup.object().shape(generateValidationSchema());

  return (
    <>
      <Form
        initialValues={{
          email,
          name,
          jobTitle,
          company,
          date: selectDefaultValue.value,
          webinar,
        }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubscribe}
        width="100%"
        alignItems="center"
      >
        {hasNameField && (
          <TextInput name="name" label={t('notify_form.name')} width={1} />
        )}
        {hasEmailField && (
          <TextInput
            name="email"
            label={t('notify_form.email')}
            width={1}
            disabled={Boolean(email)}
          />
        )}
        {hasJobTitleField && (
          <TextInput
            name="jobTitle"
            label={t('notify_form.job_title')}
            width={1}
          />
        )}
        {hasCompanyField && (
          <TextInput
            name="company"
            label={t('notify_form.company')}
            width={1}
          />
        )}
        {hasDateField && dates && (
          <Select
            aria-label={t('notify_form.date_aria')}
            name="date"
            label={t('notify_form.date')}
            width={1}
            options={[
              selectDefaultValue,
              ...dates
                .filter((date) => date)
                .map((date) => ({
                  key: date,
                  value: date,
                  label: formatDate({ date }),
                })),
            ]}
          />
        )}
        {consent && (
          <Check
            id="consent"
            name="consent"
            label={t('notify_form.consent')}
            extend={() => ({
              '& label': {
                marginBottom: 0,
              },
            })}
          />
        )}
        <Button mb={4} mt={2} type="submit" state={status} variant="primary">
          {buttonTitle}
        </Button>
      </Form>
      <Meta textAlign="center" mb={[4, null, 0]}>
        <Trans
          components={{
            terms: (
              <Link href="/terms" target="_blank" rel="noopener noreferrer" />
            ),
            policies: (
              <Link
                href="/policies"
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
          }}
        >
          {t('notify_form.accept_terms_and_policies')}
        </Trans>
      </Meta>
    </>
  );
};
