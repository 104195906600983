import * as React from 'react';
import { Box, Button, Text, type ButtonT } from '@mentimeter/ragnar-ui';
import { HeartIcon, LoaderIcon } from '@mentimeter/ragnar-visuals';
import { usePathname } from '@mentimeter/next-navigation';
import { Link } from 'src/ui/actions';
import AuthModalClicker from 'src/utils/components/AuthModalClicker';
import { DEFAULT_LOCALE } from 'src/constants.cjs';
import { useTranslation } from 'react-i18next';
import { trackUser } from '@api/tracking/client';
import User from '../../components/User';
import { H2, H3 } from '../../ui/typography';
import { useAppState } from '../../appState';
import { NotifyForm } from './NotifyForm';
import type { FormValuesT } from './types';
import { useSubscribe } from './useSubscribe';

const CAMPAIGN_ID = '701TX000006kyYpYAI';

const SuccessState = () => {
  return (
    <>
      <HeartIcon color="negative" size={5} />
      <H3 textAlign="center" mb={0}>
        Thank you for subscribing!
      </H3>
    </>
  );
};

interface DownloadGatedContentT {
  fileUrl: string;
  trackingEvent: string;
  salesforceCampaignId: string;
}
export const DownloadGatedContent = ({
  fileUrl,
  trackingEvent,
  salesforceCampaignId = CAMPAIGN_ID,
}: DownloadGatedContentT) => {
  const { user } = useAppState();
  const { status, handleSubscribe } = useSubscribe(trackingEvent, {
    'Salesforce Campaign ID': salesforceCampaignId,
  });
  const { t } = useTranslation('common');
  if (status === 'success') {
    return (
      <Box p={4} alignItems="center">
        <H3 mb={2}>{t('notify_form.check_inbox')}</H3>
        {fileUrl && (
          <Button
            variant="subtle"
            href={fileUrl}
            target="_blank"
            rel="noopener noreferrer"
            data-testid="download-button"
          >
            {t('notify_form.click_to_download')}
          </Button>
        )}
      </Box>
    );
  }
  return (
    <NotifyForm
      buttonTitle={t('notify_form.send_pdf')}
      formFields={{
        email: user?.email || '',
        jobTitle: '',
        company: '',
        name: user?.name || '',
      }}
      status={status}
      handleSubscribe={handleSubscribe}
      consent
    />
  );
};

export const SubscribeToLegalPackageUpdates = () => {
  const { user } = useAppState();
  const { status, handleSubscribe } = useSubscribe(
    'Subscribed to legal package updates',
  );

  return (
    <Box width="100%" alignItems="center">
      {status === 'success' ? (
        <SuccessState />
      ) : (
        <NotifyForm
          formFields={{
            email: user?.email || '',
          }}
          buttonTitle="Subscribe"
          handleSubscribe={handleSubscribe}
          status={status}
        />
      )}
    </Box>
  );
};

interface SignUpForWebinarT {
  dates: string[];
  webinar: string;
  onDemandUrl: string;
}

export const SignUpForWebinar = ({
  dates,
  webinar,
  onDemandUrl,
}: SignUpForWebinarT) => {
  const [status, setStatus] = React.useState<ButtonT['state']>();
  const pathname = usePathname();
  const { i18n } = useTranslation();
  const { language: locale } = i18n;
  const continueUrl = `${
    locale !== DEFAULT_LOCALE ? `/${locale}` : ''
  }${pathname}`;

  const handleWebinarRegistration = (data: FormValuesT) => {
    if (status === 'loading') {
      return;
    }
    setStatus('loading');
    const { name, email, webinar, date, jobTitle } = data;
    const parsedEmail = (email || '').toLowerCase();
    trackUser({
      event: 'Registered for webinar',
      properties: {
        name,
        'webinar date': date,
        'webinar name': webinar,
        'job title': jobTitle,
        email: parsedEmail,
        'webinar live url': `${window.location.href.split('?')[0]}/live`, // remove query string (if exists)
        'webinar on demand url': onDemandUrl,
      },
    });
    setStatus('success');
  };

  return (
    <>
      <User.Loading>
        <Box
          alignItems="center"
          justifyContent="center"
          flex="1"
          width="100%"
          height="100%"
        >
          <LoaderIcon />
        </Box>
      </User.Loading>
      <User.Authenticated>
        {(user) =>
          status === 'success' ? (
            <Box p={2} alignItems="center">
              <H2
                mb={2}
                textAlign="center"
                fontSize={4}
                lineHeight="heading"
                color="text"
                fontWeight="semiBold"
              >
                You are now registered!
              </H2>
              <Text mt={3} mb={3} textAlign="center">
                A link to the webinar has been sent to {user.email}.
              </Text>
            </Box>
          ) : (
            <>
              <H2
                mt={3}
                mb={3}
                textAlign="center"
                lineHeight="heading"
                color="text"
                fontWeight="semiBold"
              >
                Register for free
              </H2>
              <NotifyForm
                buttonTitle="Register now"
                formFields={{
                  email: user.email,
                  jobTitle: '',
                  name: user.name || '',
                  date: '',
                  webinar,
                }}
                dates={dates}
                status={status}
                handleSubscribe={handleWebinarRegistration}
              />
            </>
          )
        }
      </User.Authenticated>
      <User.NotExisting>
        <Box p={4} alignItems="center" width="100%">
          <Text mt={2} mb={2} textAlign="center">
            You need to be logged in to register for the webinar
          </Text>
          <Text mt={3} mb={3} textAlign="center">
            Don&apos;t have an account?{' '}
            <AuthModalClicker
              showSignup
              continueUrl={continueUrl}
              referral={pathname || ''}
              render={({ onClick }) => <Link onClick={onClick}>Sign up</Link>}
            />
          </Text>
          <AuthModalClicker
            showSignup={false}
            continueUrl={continueUrl}
            referral={pathname || ''}
            render={({ onClick }) => (
              <Button variant="primary" onClick={onClick} mt={1}>
                Log in
              </Button>
            )}
          />
        </Box>
      </User.NotExisting>
    </>
  );
};
